<template>
  <div class="video-container">
    <video style="height: 100%;" autoplay loop muted>
      <source src="https://proplat-public-images.s3.amazonaws.com/home.mp4" type="video/mp4">
      Tu navegador no soporta el elemento de video.
    </video>
    <div class="overlay" style="display: flex; align-items: flex-start; flex-direction: column; justify-content: center; padding-left: 40px;">
      <h2 class="mon-bold" style="font-size: min(max(35px, 5vw), 90px); text-wrap: wrap; max-width: 1050px; color: #304458; margin-bottom: 45px;">{{ texts.banner.titleSection }}</h2>
      <h3 class="mon" style="max-width: 1050px; font-size: 22px; color: #AEB7BF; padding-left: 10px;">{{ texts.banner.subtitleSection }}</h3>
      <div style=" display: flex; margin-top: 30px; flex-wrap: wrap; gap: 15px">
        <a href="https://www.vendors.proplat.com/signup" target="_blank" style="text-decoration: none;">
          <v-btn
            class="button-register mon-bold"
            style="background-color: #FFC431;"
            >
              {{ texts.banner.buttonProvider  }}
          </v-btn>
        </a>
        <v-btn
          to="/welcome"
          outlined
          class="button-register mon-bold "
          style="background-color: white; color: #FFC431 !important;"
          >
            {{ texts.banner.buttonBuyer }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeBanenrLayout",
  data() {
    return {
      //VARIABLES
      isResponsive: false
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
  },
};
</script>

<style scoped>

.video-container {
  position: relative;
  width: 100vw; /* 100% del ancho de la ventana */
  height: 100vh; /* 100% de la altura de la ventana */
  overflow: hidden; /* Para asegurar que el video no desborde el div */
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Para ajustar el video al tamaño del div sin distorsión */
}
.button-register {
  width: 260px !important;
  height: 59px !important;
  background-color: #FFC431;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d !important;
  font-size: 16px !important;
  text-transform: initial;
  padding: 20px 20px;
}
.fruits-image {
  position: absolute;
  width: 600px;
  right: -20px;
  top: 0px;
  overflow: hidden;
}

.txt-title {
  color: #ffffff;
  text-align: left;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000095;
  opacity: 1;
  font-size: 30px;
}

.txt-description {
  color: #ffffff;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000095;
  opacity: 1;
  font-size: 14px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to right, rgb(255, 255, 255) 40%, rgba(0, 0, 0, 0));
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }

  .fruits-image {
    left: 50%;
    transform: translate(-50%, 0%);
    width: 350px;
  }

  .txt-title {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .txt-description {
    font-size: 12px;
  }
}
</style>

<style>
.bg-welcome-proplat {
  background: url("../../../assets/images/bg-banner-welcome.png");
  width: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

@media (max-width: 960px) {
  .bg-welcome-proplat {
    padding: 15px;
  }
}
</style>